body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5c7a9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.aboutMe {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-flow: row;
  grid-gap: 20px;
  margin: auto;
  max-width: 1200px;
}

.projects {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-flow: row;
  grid-gap: 20px;
  margin: auto;
  max-width: 1200px;
}

@media only screen and (max-width: 1300px) {
  .aboutMe {
    max-width: 1000px;
  }

  .projects {
    max-width: 1000px;
  }
}

@media only screen and (max-width: 1100px) {
  .aboutMe {
    max-width: 900px;
  }

  .projects {
    max-width: 900px;
  }
}

@media only screen and (max-width: 1020px) {
  .aboutMe {
    max-width: 700px;
  }

  .projects {
    max-width: 700px;
  }
}

.btn {
  line-height: 50px;
  height: 50px;
  text-align: center;
  width: 250px;
  cursor: pointer;
  background: #f5c7a9;
}

.btn-three {
  color: #fff;
  transition: all 0.5s;
  position: relative;
}
.btn-three::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.1);
  transition: all 0.3s;
}
.btn-three:hover::before {
  opacity: 0;
  transform: scale(0.5, 0.5);
}
.btn-three::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s;
  border: 1px solid rgba(255, 255, 255, 0.5);
  transform: scale(1.2, 1.2);
}
.btn-three:hover::after {
  opacity: 1;
  transform: scale(1, 1);
}
a {
  position: relative;
  display: block;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 65px;
  border-radius: 50%;
  font-size: 30px;
  border: solid 3px transparent;
  transition: 0.5s;
}

.contact-sidebar > a:hover {
  border: solid 3px #fff;
}

.datenschutz a {
  text-align: center;
  font-size: 20px;
  text-decoration: none;
  margin: 5px;
  width: fit-content;
}
